/* eslint @nx/enforce-module-boundaries: 'warn' */

import { useCallback } from 'react';
import { SaveProduct } from '@croquiscom/web2app';

import { ActionOptionalLog, ZigzagEvent } from '@common/app-manager';

import { useNonLoginLimitABT } from './useNonLoginLimitABT';

/**
 * ZigZag App에서 사용하는 웹뷰에서 like 동작 시 전달하는 값
 * @description  inAppLikeProductPayload: sendUBL을 이중으로 전송해야하는 경우, inAppLikeProductPayload.sendLog를 추가합니다. sendLog과 Log의 인자는 다를 수 있습니다.
 * @docs https://www.notion.so/croquis/widget-like-product-bb6ac56bf316499ea5f85e30faadad03
 */
export interface InAppLikeProductPayload extends SaveProduct {
  like: boolean;
  log?: ActionOptionalLog;
  sendLog?: (checked: boolean) => void;
}

interface UseLikeConfig {
  onLikeChange: (checked: boolean, productId: string) => void;
}

const handleSaveProduct = async ({ log, ...rest }: Omit<InAppLikeProductPayload, 'like'>) => {
  return await ZigzagEvent.sp(
    {
      browsingType: rest.browsingType,
      shopId: rest.shopId,
      shopProductNo: rest.shopProductNo,
      catalogProductId: rest.catalogProductId,
      title: rest.title,
      goodsUrl: rest.goodsUrl,
      imageUrl: rest.imageUrl,
      price: rest.price,
      discountRate: rest.discountRate,
      isZpay: rest.isZpay,
      isFreeShipping: rest.isFreeShipping,
    },
    log,
  );
};

const handleRemoveSaveProduct = async ({
  log,
  shopId,
  shopProductNo,
  catalogProductId,
}: Omit<InAppLikeProductPayload, 'like'>) => {
  return await ZigzagEvent.rsp({ shopId, shopProductNo, catalogProductId }, log);
};

export const useInAppLikeProduct = ({ onLikeChange }: UseLikeConfig) => {
  const isABT = useNonLoginLimitABT();

  const onInAppLikeProduct = useCallback(
    async ({ like, ...args }: InAppLikeProductPayload) => {
      const productId = args.catalogProductId || '';

      if (isABT) {
        const result = like ? await handleSaveProduct(args) : await handleRemoveSaveProduct(args);
        onLikeChange(result.isSaved, productId);
        return;
      }

      onLikeChange(like, productId);
      like ? handleSaveProduct(args) : handleRemoveSaveProduct(args);
    },
    [isABT, onLikeChange],
  );

  return { onInAppLikeProduct };
};
